import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import FamilyTravel from "./pages/FamilyTravel";
import TestimonialsCards from "./pages/TestimonialsCards";
import LeaveReview from "./pages/LeaveReview";
import ContactUs from "./pages/ContactUs";
import OurSpecialities from "./pages/OurSpecialities";
import AboutusView from "./pages/AboutusView";
import Destination from "./pages/Destination";
import Experience from "./pages/Experience";
import Month from "./pages/Month";
import MonthlyDestinationHighlights from "./pages/MonthlyDestinationHighlights";
import DestinationWedding from "./pages/DestinationWedding";
import HoneyMoon from "./pages/HoneyMoon";
import Anniversary from "./pages/Anniversary";
import Inclusivecaccation from "./pages/InclusiveVaccation";
import OceanCruise from "./pages/OceanCruise";
import RiverCruise from "./pages/RiverCruise";
import SkiVacation from "./pages/SkiVacation";
import DisneyVacation from "./pages/DisneyVacation";
import UniversalStudioVaction from "./pages/UniversalStudioVacation";
import Blog from "./pages/Blog";
import GroupTravel from "./pages/GroupTravel";
import Inspiration from "./pages/Inspiration";
import BlogDetailing from "./pages/BlogDetailing";
import ScrollToTop from "./components/ScrollToTop";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CustomerService from "./pages/CustomerService";
import Accessibility from "./pages/Accessibility";
import CountryDestination from "./pages/CountryDestination";

const App: React.FC = () => {
  React.useEffect(() => {
    const fonts = [
      {
        href: "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap",
        rel: "stylesheet",
      },
    ];

    fonts.forEach(({ href, rel }) => {
      const link = document.createElement("link");
      link.href = href;
      link.rel = rel;
      document.head.appendChild(link);
    });

    return () => {
      fonts.forEach(({ href }) => {
        const link = document.querySelector(`link[href="${href}"]`);
        if (link) document.head.removeChild(link);
      });
    };
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/family-travel" element={<FamilyTravel />} />
          <Route path="/testimonials" element={<TestimonialsCards />} />
          <Route path="/leave-review" element={<LeaveReview />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/ourspecialities" element={<OurSpecialities />} />
          <Route path="/aboutus" element={<AboutusView />} />
          <Route path="/destination" element={<Destination />} />
          <Route path="/experiences" element={<Experience />} />
          <Route path="/month" element={<Month />} />
          <Route path="/destination-wedding" element={<DestinationWedding />} />
          <Route path="/honeymoon-geteway" element={<HoneyMoon />} />
          <Route path="/anniversary" element={<Anniversary />} />
          <Route path="/inclusive-vacation" element={<Inclusivecaccation />} />
          <Route path="/ocean-cruise" element={<OceanCruise />} />
          <Route path="/river-cruise" element={<RiverCruise />} />
          <Route path="/ski-vacation" element={<SkiVacation />} />
          <Route path="/disney-vacation" element={<DisneyVacation />} />
          <Route path="/group-travel" element={<GroupTravel />} />
          <Route
            path="/Universalstudio-vacation"
            element={<UniversalStudioVaction />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/inspiration" element={<Inspiration />} />
          <Route path="/blogDetails" element={<BlogDetailing />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/customer-service" element={<CustomerService />} />
          <Route path="/accessibility" element={<Accessibility />} />
          <Route path="/country" element={<CountryDestination />} />
          <Route path="/country/:id" element={<CountryDestination />} />

          <Route
            path="/monthly/:id"
            element={<MonthlyDestinationHighlights />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
