import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

interface DestinationDetail {
  destinationId: string;
  countryName: string;
  destinationDescription: string;
  destinationImagesPath: string[];
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const BestPlacesToVisitSection: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [destinationDetails, setDestinationDetails] =
    useState<DestinationDetail | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDestinationDetails = async () => {
      try {
        const baseUrl = "https://demo.decordtech.com/tadmin/api/";
        const response = await axios.post(
          `${baseUrl}/pages/monthPageDetails`,
          { monthId: id },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success && response.data.data.destinationDetails) {
          setDestinationDetails(response.data.data.destinationDetails[0]);
        } else {
          throw new Error("Failed to fetch destination details.");
        }
      } catch (err) {
        console.error("Error fetching destination details:", err);
        setError(
          "Failed to fetch destination details. Please try again later."
        );
      }
    };

    fetchDestinationDetails();
  }, []);

  return (
    <div className="px-4 md:px-20">
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : destinationDetails ? (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-0">
          {/* First Column */}
          <div className="col-span-1 md:col-span-4 flex flex-col gap-y-4 justify-between">
            <div>
              <h3 className="text-xl sm:text-3xl lg:text-2xl font-bold font-akshar uppercase">
                {destinationDetails.countryName}
              </h3>
              <p className="lg:text-base sm:tracking-wide font-poppins mt-2 sm:text-base md:text-lg text-gray-700 text-justify sm:text-left">
                {destinationDetails.destinationDescription}
              </p>
              <a
                href="#"
                className="text-black font-semibold underline hover:no-underline mt-2"
              >
                Read More
              </a>
            </div>
            <div className="flex-grow mt-4 md:mt-0">
              <img
                src={destinationDetails.destinationImagesPath[0]}
                alt={`${destinationDetails.countryName} landscape`}
                className="rounded-lg w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Second Column */}
          <div className="col-span-1 md:col-span-4 flex flex-col gap-y-4 justify-between">
            <div className="flex-grow">
              <img
                src={destinationDetails.destinationImagesPath[1]}
                alt={`${destinationDetails.countryName} landscape`}
                className="rounded-lg w-full h-full object-cover"
              />
            </div>
            <div className="flex-grow mt-4 md:mt-0">
              <img
                src={destinationDetails.destinationImagesPath[2]}
                alt={`${destinationDetails.countryName} landscape`}
                className="rounded-lg w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Third Column */}
          <div className="col-span-1 md:col-span-4 flex flex-col justify-between">
            <div className="flex-grow">
              <img
                src={destinationDetails.destinationImagesPath[3]}
                alt={`${destinationDetails.countryName} landscape`}
                className="rounded-lg w-full h-full object-cover mt-4 md:mt-0"
              />
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BestPlacesToVisitSection;
