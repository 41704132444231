import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData, ApiResponse } from "../services/api"; 

interface MonthlyDestination {
  image: string;
  monthId: string;
  title: string;
  subTitle: string;
}

const MonthGallery: React.FC = () => {
  const [monthlyDestinations, setMonthlyDestinations] = useState<MonthlyDestination[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [fetchingMonth, setFetchingMonth] = useState(false);

  const fetchMonthlyDestinations = async () => {
    try {
      const response: ApiResponse<{ monthlyDestinations: MonthlyDestination[] }> | null = await fetchData<{
        monthlyDestinations: MonthlyDestination[];
      }>({
        url: "pages/get",
        data: { pageId: 16 },
      });

      const destinations = response?.data?.monthlyDestinations;
      if (destinations && destinations.length > 0) {
        setMonthlyDestinations(destinations);
      } else {
        throw new Error("Monthly destinations not found.");
      }
    } catch (error) {
      setError("Failed to load destinations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMonthlyDestinations();
  }, []);

  const handleMonthClick = (monthId: string) => {
    if (!fetchingMonth) {
      window.location.href = `/monthly/${monthId}`;
    }
  };

  return (
    <div className="container mx-auto my-10 px-4 md:px-28 mt-14">
      <h2 className="mt-2 tracking-wide sm:mt-5 text-xl lg:text-3xl font-bold font-akshar mb-2 sm:mb-4 uppercase text-center">
        Month
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4 sm:gap-6">
        {monthlyDestinations.map((month) => (
          <div
            key={month.monthId} // Use monthId for the key
            onClick={() => handleMonthClick(month.monthId)} // Use monthId for navigation
            className="cursor-pointer transition-transform duration-300 hover:scale-105 relative h-44 sm:h-64 rounded-lg overflow-hidden bg-cover bg-center shadow-md"
            style={{
              backgroundImage: `url(${month.image})`,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center">
              <h5 className="text-white font-mplus2 text-sm sm:text-lg font-semibold uppercase">
                {month.title}
              </h5>
              <h5 className="text-white font-mplus2 text-xs sm:text-xs font-normal">
                {month.subTitle}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthGallery;
