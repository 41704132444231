import React, { useState } from "react";
import HeaderMonthly from "../components/HeaderMonthly";
import BestCountry from "../components/BestCountry";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import HeaderCountry from "../components/HeaderCountry";
import OverviewCountry from "../components/OverviewCountry";

const CountryDestination: React.FC = () => {
  return (
    <div>
      <HeaderCountry />
      <OverviewCountry />
      <div className="mt-8">
        <BestCountry />
      </div>
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default CountryDestination;
