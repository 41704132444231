import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardItem from "../components/CardItem";
import { fetchData, ApiResponse } from "../services/api";

interface ApiData {
  specialities: {
    id: string;
    name: string;
    url?: string;
    image: string;
    page?: string;
    pageId?: string;
  }[];
}

const CardGrid: React.FC = () => {
  const [specialities, setSpecialities] = useState<ApiData["specialities"]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpecialities = async () => {
      try {
        const response: ApiResponse<ApiData> | null = await fetchData<ApiData>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        if (response?.data?.specialities) {
          setSpecialities(response.data.specialities);
        }
      } catch {
        // Handle fetch errors silently
      } finally {
        setLoading(false);
      }
    };

    fetchSpecialities();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white py-12 relative">
      <div className="grid grid-cols-1 font-mplus2 uppercase font-semibold sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 p-4 sm:p-5 md:px-20">
        {specialities.map((speciality) => (
          <CardItem
            key={speciality.id}
            imageUrl={speciality.image}
            title={speciality.name}
            onClick={() => {
              if (speciality.url) {
                window.open(speciality.url, "_blank");
              } else if (speciality.pageId) {
                navigate(`/page/${speciality.pageId}`);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
