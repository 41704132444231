import React, { useState, useEffect } from "react";
import ProfileImage1 from "../assets/images/Rob.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { fetchData } from "../services/api";
import { Link } from "react-router-dom";

interface AdvisorData {
  id: string;
  name: string;
  image: string;
  designation: string;
  description: string;
}

const TravelAdvisors: React.FC = () => {
  const [robHaleData, setRobHaleData] = useState<AdvisorData | null>(null);

  useEffect(() => {
    const fetchAdvisorsData = async () => {
      try {
        const response = await fetchData<{ advisors: AdvisorData[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        const advisors = response?.data?.advisors;

        if (advisors?.length) {
          const robHale = advisors.find(
            (advisor) => advisor.name.toLowerCase() === "rob hale"
          );

          if (robHale) {
            setRobHaleData(robHale);
          } else {
            console.warn("Rob Hale's data not found.");
          }
        } else {
          console.warn("No advisors data found in API response.");
        }
      } catch (error) {
        console.error("Error fetching advisors data:", error);
      }
    };

    fetchAdvisorsData();
  }, []);

  return (
    <section className="bg-white py-12">
      <div className="mx-auto">
        <h2 className=" text-xl  lg:text-3xl font-bold font-akshar text-3xl font-bold text-center mb-6 uppercase tracking-wide">
          meet your travel advisors
        </h2>

        <div className="w-full lg:w-screen relative px-4 lg:px-[100px]">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-10 items-center lg:items-start w-full lg:w-[90%]">
            <div className="w-40 h-40 lg:w-[300px] lg:h-[300px] rounded-sm  ">
              <img
                src={robHaleData?.image || ProfileImage1}
                alt={robHaleData?.name || "Advisor"}
                className=" h-full w-full object-fill rounded-md"
              />
            </div>
            <div className="flex-1 mt-3 lg:mt-0 bg-gradient-to-b from-[#F0F5FC]  to-[#FCFDFE] rounded-lg shadow-sm p-4 lg:p-6  text-center md:text-left">
              <h3 className="text-2xl font-semibold text-gray-800 uppercase font-mplus2">
                {robHaleData?.name || "Advisor Name"}
              </h3>

              <div className="flex flex-col lg:flex-row justify-center lg:justify-between border-b-2 border-gray-300 py-2 w-full">
                <p className="text-sm font-bold font-akshar mb-2 lg:mb-0 text-center lg:text-left">
                  {robHaleData?.designation || "Advisor Designation"}
                </p>

                <div className="flex space-x-4 mt-2 lg:mt-0 justify-center lg:justify-start">
                  <a
                    href="#"
                    className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="#"
                    className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="#"
                    className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                  >
                    <FaLinkedinIn />
                  </a>
                  <a
                    href="#"
                    className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                  >
                    <FaTwitter />
                  </a>
                </div>
              </div>

              <p className="text-sm lg:text-base font-poppins text-black font-light mt-4  tracking-wide text-justify sm:text-left">
                {robHaleData?.description ||
                  "Rob Hale's description will appear here."}
              </p>
              <div className="flex justify-center lg:justify-start">
                <button className="mt-4 py-2 px-4 lg:px-6 border border-black text-black font-semibold border-2 rounded-md hover:bg-gray-700 hover:text-white transition">
                  <Link to="/aboutus">About</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelAdvisors;
