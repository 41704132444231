import React, { useState } from "react";
import Header from "../components/Header";
import { GoClock } from "react-icons/go";
import contactimage from "../assets/images/Contactus.png";
import contactlogo from "../assets/images/contact-logo.png";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import TripPlannerForm from "../components/TripPlannerForm";
import RegisterContact from "../components/RegisterContact";
import ContactSection from "../components/ContactSection";
import ContactUsSection from "../components/ContactUsSection";

const ContactUs: React.FC = () => {
  // Define the state for the toggle switch
  const [enabled, setEnabled] = useState(false);

  // Function to toggle the switch
  const toggleSwitch = () => {
    setEnabled(!enabled);
  };

  return (
    <div>
      <Header />

      <RegisterContact />
      <ContactUsSection />

      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default ContactUs;
