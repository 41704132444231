import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/images/logo.svg";
import { fetchData, ApiResponse } from "../services/api"; // Import the updated types
import {
  FaBars,
  FaTimes,
  FaPlay,
  FaPause,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";

interface NavbarProps {
  heading?: string;
  subHeading?: string;
}

interface ApiData {
  banners: {
    bannerId: string;
    bannerText: string;
    bannerLink?: string;
    subText: string;
    image?: string;
    bannerVideo: string;
    buttonLink?: string;
    buttonText?: string;
  }[];
}

const Navbar: React.FC<NavbarProps> = ({ heading, subHeading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isSpecialtiesOpen, setIsSpecialtiesOpen] = useState(false);
  const [isThemeParkOpen, setIsThemeParkOpen] = useState(false);
  const [bannerData, setBannerData] = useState({
    video: "",
    heading: heading || "",
    subHeading: subHeading || "",
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response: ApiResponse<ApiData> | null = await fetchData<ApiData>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        const banners = response?.data?.banners;

        if (banners?.length) {
          const banner = banners[0];
          setBannerData({
            video: banner.bannerVideo,
            heading: banner.bannerText,
            subHeading: banner.subText,
          });
        } else {
          console.warn("No b  anners found in API response.");
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBannerData();
  }, []);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying((prevState) => !prevState);
    }
  };

  const toggleSpecialties = () => {
    setIsSpecialtiesOpen((prevState) => !prevState);
  };

  const toggleThemePark = () => {
    setIsThemeParkOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  return (
    <nav className="relative h-screen bg-cover bg-center">
      {bannerData.video && (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src={bannerData.video} type="video/mp4" />
        </video>
      )}

      {/* Overlay Text Section */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-10 text-white text-center px-4 md:px-0">
        <h1 className="tracking-widest uppercase text-3xl sm:text-4xl md:text-5xl font-bold leading-tight md:leading-normal">
          {bannerData.heading}
        </h1>
        <p className="mt-4 text-lg sm:text-2xl md:text-3xl font-medium tracking-widest leading-snug md:leading-normal">
          {bannerData.subHeading}
        </p>
        <div
          onClick={togglePlayPause}
          className="mt-4 md:mt-8 cursor-pointer text-white border border-[#FFFFFF] rounded-full p-4 sm:p-6 md:p-8 flex items-center justify-center w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 overflow-hidden"
        >
          {isPlaying ? (
            <FaPause className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12" />
          ) : (
            <FaPlay className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12" />
          )}
        </div>
      </div>

      <div className="flex items-center justify-between px-4 md:px-20 z-20 relative w-full">
        <div className="flex-shrink-0">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="h-20 w-20npm stae md:h-24 md:w-24"
            />
          </Link>
        </div>

        <ul className="hidden sm:flex items-center space-x-8 text-white text-neutral-100">
          <li className="hover:text-white cursor-pointer">
            <Link to="/" className="p-2 font-semibold">
              HOME
            </Link>
          </li>

          <li className="relative group cursor-pointer hover:text-blue-200">
            <Link
              to="/ourspecialities"
              className="p-3 font-semibold  uppercase text-neutral-100"
            >
              Our Specialties
            </Link>
            <div className="absolute left-0 uppercase mt-2 w-64 bg-gradient-to-br from-blue-900 to-blue-700 text-white rounded-lg shadow-lg z-20 hidden group-hover:block transition-all duration-200 transform-gpu">
              <ul className="py-2">
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/family-travel" className="block">
                    Family Travel
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/group-travel" className="block">
                    Group Travel
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/destination-wedding" className="block">
                    Destination Wedding
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/honeymoon-geteway" className="block">
                    Honeymoon Getaways
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/anniversary" className="block">
                    Anniversary
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/inclusive-vacation" className="block">
                    All Inclusive Vacation
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/ocean-cruise" className="block">
                    Ocean Cruise
                  </Link>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/river-cruise" className="block">
                    River Cruise
                  </Link>
                </li>
                <li className="relative group px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <div className="block">Theme Park Vacation</div>
                  <div className="absolute top-0 left-full ml-2 w-60 bg-blue-800 text-white rounded-lg shadow-lg hidden group-hover:block transition-all duration-200 transform-gpu">
                    <ul className="py-2">
                      <li className="px-4 py-3 hover:bg-blue-700 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/disney-vacation" className="block">
                          Disney Vacation
                        </Link>
                      </li>
                      <li className="px-4 py-3 hover:bg-blue-700 cursor-pointer rounded transition-colors duration-200">
                        <Link to="/Universalstudio-vacation" className="block">
                          Universal Studios Vacation
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="px-4 py-3 hover:bg-blue-600 cursor-pointer rounded transition-colors duration-200">
                  <Link to="/ski-vacation" className="block">
                    Ski Vacation
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="uppercase relative group cursor-pointer hover:text-blue-200">
            <Link to="/aboutus" className="p-2 font-semibold">
              ABOUT US
            </Link>
            {/* Dropdown */}
            <div className="absolute left-0 mt-2 w-48 rounded-lg shadow-lg z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform-gpu bg-gradient-to-br from-blue-900 to-blue-700 text-white">
              <ul className="py-2">
                <li className="px-4 py-2 hover:bg-blue-600 cursor-pointer transition-colors duration-200">
                  <Link to="/testimonials" className="block">
                    TESTIMONIALS
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-blue-600 cursor-pointer transition-colors duration-200">
                  <Link to="/leave-review" className="block">
                    Leave a Review
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="hover:text-white cursor-pointer">
            <Link to="/blog" className="p-2 font-semibold">
              BLOG
            </Link>
          </li>

          <li className="uppercase relative group cursor-pointer hover:text-blue-200">
            <Link to="/" className="p-2 font-semibold">
              PLANS
            </Link>
            <div className="absolute bg-gradient-to-br from-blue-900 to-blue-700 left-0 mt-2 w-48 text-white rounded-lg shadow-lg z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform">
              <ul className="py-2">
                <li className="px-4 py-2 cursor-pointer hover:bg-blue-600 transition-colors duration-200">
                  <Link to="/month" className="block">
                    Month
                  </Link>
                </li>
                <li className="px-4 py-2 cursor-pointer hover:bg-blue-600 transition-colors duration-200">
                  <Link to="/destination" className="block">
                    Destination
                  </Link>
                </li>
                <li className="px-4 py-2 cursor-pointer hover:bg-blue-600 transition-colors duration-200">
                  <Link to="/experiences" className="block">
                    Experience
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="hover:text-white cursor-pointer">
            <Link
              to="/contact-us"
              className="p-2 font-semibold text-neutral-100"
            >
              CONTACT US
            </Link>
          </li>

            <button>
              <Link
                to="/contact-us"
                className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
              >
                Ready To Travel?
              </Link>
            </button>
        </ul>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white">
            <FaBars size={24} />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="fixed inset-0 z-40">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={toggleMenu}
          ></div>

          <div className="relative bg-white text-gray-800 w-full h-full p-8 z-50 transition-transform transform translate-x-0 duration-300  shadow-xl max-h-screen overflow-y-auto">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-yellow-500 transition duration-300"
              onClick={toggleMenu}
            >
              <FaTimes size={28} />
            </button>

            <ul className="space-y-3 text-lg font-semibold mt-8 uppercase tracking-wider">
              <li>
                <Link
                  to="/"
                  onClick={toggleMenu}
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Home
                </Link>
              </li>

              <li className="cursor-pointer" onClick={toggleSpecialties}>
                <div className="flex justify-between items-center py-3 px-4 hover:bg-gray-100 rounded-md transition duration-200 ease-in">
                  <Link
                    to="/ourspecialities"
                    className="text-gray-800 font-mplus2 hover:text-blue-600 font-semibold"
                  >
                    OUR SPECIALTIES
                  </Link>
                  <span className="text-lg">
                    {isSpecialtiesOpen ? "▲" : "▼"}
                  </span>
                </div>
              </li>

              {isSpecialtiesOpen && (
                <ul className="space-y-2 text-base bg-gray-50 p-4 rounded-lg shadow-inner">
                  <li>
                    <Link
                      to="/family-travel"
                      className="block p-3  font-mplus2 text-gray-800 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      Family Travel
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/group-travel"
                      className="block p-3  font-mplus2 text-gray-800 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      Group-Travel
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/destination-wedding"
                      className="block p-3 text-gray-800 font-mplus2 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      Destination Wedding
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/honeymoon-geteway"
                      className="block p-3 text-gray-800 font-mplus2 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      HoneyMoon Getaways
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/anniversary"
                      className="block p-3 text-gray-800 font-mplus2 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      Anniversary
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/inclusive-vacation"
                      className="block p-3 text-gray-800 font-mplus2 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      All Inclusive Vacation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ocean-cruise"
                      className="block p-3  text-gray-800 font-mplus2 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      Ocean Cruise
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/river-cruise"
                      className="block p-3 text-gray-800 font-mplus2 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      River Cruise
                    </Link>
                  </li>
                  <li className="cursor-pointer">
                    {/* Dropdown Header */}
                    <div
                      className="flex justify-between items-center p-3 hover:bg-gray-100 rounded-md transition duration-200 ease-in"
                      onClick={() => setIsThemeParkOpen((prev) => !prev)} // Toggle dropdown
                    >
                      <span className="text-gray-800 font-mplus2">
                        Theme Park Vacation
                      </span>
                      <span>{isThemeParkOpen ? "▲" : "▼"}</span>
                    </div>

                    {/* Dropdown Menu */}
                    {isThemeParkOpen && (
                      <ul className="space-y-2 bg-gray-50 p-2 rounded-lg shadow-inner">
                        <li>
                          <Link
                            to="/disney-vacation"
                            className="block p-3 font-mplus2 text-gray-800 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                            onClick={() => setIsThemeParkOpen(false)} // Close dropdown after clicking link
                          >
                            Disney Vacation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/universal-studios-vacation"
                            className="block p-3 font-mplus2 text-gray-800 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                            onClick={() => setIsThemeParkOpen(false)} // Close dropdown after clicking link
                          >
                            Universal Studios
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li>
                    <Link
                      to="/ski-vacation"
                      className="block p-3 font-mplus2 text-gray-800 hover:text-yellow-500 hover:bg-blue-600 rounded-md transition duration-300"
                      onClick={toggleMenu}
                    >
                      Ski Vacation
                    </Link>
                  </li>
                </ul>
              )}

              <li>
                <Link
                  to="/aboutus"
                  onClick={toggleMenu}
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/testimonials"
                  onClick={toggleMenu}
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  to="/leave-review"
                  onClick={toggleMenu}
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Leave a Review
                </Link>
              </li>

              <li>
                <Link
                  to="/month"
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Month
                </Link>
              </li>
              <li>
                <Link
                  to="/destination"
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Destination
                </Link>
              </li>
              <li>
                <Link
                  to="/experiences"
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Experience
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="block pl-4 py-3 font-mplus2 rounded-md transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Blog
                </Link>
              </li>

              <li>
                <Link
                  to="/contact-us"
                  onClick={toggleMenu}
                  className="block pl-4 py-3 rounded-md font-mplus2 transition duration-300 hover:bg-gray-100 hover:text-yellow-500 font-medium"
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            <button>
              <Link
                to="/contact-us"
                onClick={toggleMenu}
                className="mt-8 w-full px-4 py-3 bg-blue-900 font-mplus2 tracking-widest  text-white font-bold text-xl rounded-lg hover:bg-yellow-400 transition duration-300 shadow-lg"
              >
                Ready To Travel?
              </Link>
            </button>
          </div>
        </div>
      )}

      <div className="absolute left-2 bottom-8 flex flex-col items-center space-y-4 text-white sm:left-4 sm:bottom-4 sm:space-y-2 md:space-y-4">
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-4 sm:p-2 md:p-4"
        >
          <FaFacebookF />
        </a>
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-4 sm:p-2 md:p-4"
        >
          <FaInstagram />
        </a>
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-4 sm:p-2 md:p-4"
        >
          <GrLinkedinOption />
        </a>
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-4 sm:p-2 md:p-4"
        >
          <FaTwitter />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
