import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

const testimonials = [
  {
    id: 1,
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    source: "Forbes",
  },
  {
    id: 2,
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    source: "Forbes",
  },
  {
    id: 3,
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    source: "Forbes",
  },
  {
    id: 4,
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    source: "Forbes",
  },
];

const Carousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
  });

  return (
    <div
      {...handlers}
      className="relative w-full py-8 bg-white overflow-hidden"
    >
      {/* Carousel Content */}
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="min-w-full md:min-w-[50%] lg:min-w-[33.33%] px-4 flex justify-center"
          >
            <div className="bg-white p-4 rounded-lg shadow max-w-sm text-center">
              <div className="text-4xl text-blue-600 mb-2">“</div>
              <p className="text-gray-600 mb-2">{testimonial.text}</p>
              <div className="font-bold text-blue-800">
                {testimonial.source}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
