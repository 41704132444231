import React, { useState } from "react";
import Header from "../components/Header";
import { GoClock } from "react-icons/go";
import contactimage from "../assets/images/Contactus.png";
import contactlogo from "../assets/images/contact-logo.png";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import TripPlannerForm from "../components/TripPlannerForm";
import RegisterContact from "../components/RegisterContact";

const ContactUsSection: React.FC = () => {
  // Define the state for the toggle switch
  const [enabled, setEnabled] = useState(false);

  // Function to toggle the switch
  const toggleSwitch = () => {
    setEnabled(!enabled);
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-8 sm:mt-16 px-4">
        <h3 className="uppercase text-2xl sm:text-4xl font-semibold font-alumnisans tracking-wide text-center">
          Contact Us Today and See Why We’re Denver’s #1 Travel Agency
        </h3>

        <p className="font-akshar tracking-wide text-center opacity-95 mt-4 sm:mt-5 text-sm sm:text-base">
          Honeymoon Getaways • Destination Weddings • Family Travel • Group
          Travel • Anniversary • All Inclusive Vacations • Adventure & Active
          Travel <br className="hidden sm:block" />• Ocean Cruises • River
          Cruises • Disney Vacations
        </p>

        <div className="mt-4">
          <img
            src={contactlogo}
            alt="Logo"
            className="h-16 sm:h-20 md:h-28 w-auto mx-auto"
          />

          <div className="text-center leading-6 mt-4 sm:mt-5 mb-5">
            <div className="text-xs sm:text-sm font-semibold uppercase">
              TRAVEL N RELAX.m a.m
            </div>
            <div className="text-xs sm:text-sm uppercase text-black opacity-95">
              15385 E 7th Cir, Denver, Colorado, CO 80011
            </div>
            <div className="text-xs sm:text-sm uppercase text-black opacity-95">
              (303) 317-6945
            </div>
            <div className="text-xs sm:text-sm uppercase text-black opacity-95">
              info@travelnrelax.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
