import React from "react";
import Navbar from "../components/Navbar";
import Terms from "../components/Terms";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData } from "../services/api";
import { useState, useEffect } from "react";

const TermsOfUse: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 22 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          // Update state
          setMetaData({
            pageTitle: "Destination Wedding", // Optional static page title
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });

          // Dynamically update meta tags
          const updateMetaTag = (name: string, content: string) => {
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            if (metaTag) {
              metaTag.setAttribute("content", content);
            } else {
              metaTag = document.createElement("meta");
              metaTag.setAttribute("name", name);
              metaTag.setAttribute("content", content);
              document.head.appendChild(metaTag);
            }
          };

          updateMetaTag("keywords", meta_keyword);
          updateMetaTag("description", meta_description);
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);
  return (
    <div>
      <Navbar />
      <Terms />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default TermsOfUse;
