import React, { useEffect, useState } from "react";
import AboutView from "../assets/images/AboutusView.png";
import { fetchData, ApiResponse } from "../services/api";

interface AimData {
  id: string;
  text: string;
  description: string;
  image?: string;
  buttonLink?: string;
  buttonText?: string;
}

const OurPhilosophy: React.FC = () => {
  const [aim, setAim] = useState<AimData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAimData = async () => {
      try {
        const response: ApiResponse<{ aim: AimData[] }> | null =
          await fetchData<{ aim: AimData[] }>({
            url: "pages/get",
            data: { pageId: 24 },
          });

        const aimData = response?.data?.aim;

        if (aimData && aimData.length > 0) {
          setAim(aimData[0]); // Use the first aim data
        } else {
          throw new Error("No aim data found.");
        }
      } catch (error) {
        console.error("Error fetching aim data:", error);
        setError("Failed to load content. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAimData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div className="relative mt-12 sm:mt-24">
      <img
        src={aim?.image || AboutView}
        alt={aim?.text || "Seamless Travel"}
        className="w-full h-[250px] sm:h-[500px] object-cover"
      />

      <div className="absolute inset-0 flex items-center justify-center">
        <div className="bg-white bg-opacity-70 text-center p-4 sm:p-8 rounded-md border-0 sm:border-2 sm:border-black sm:border-dashed w-[90%] sm:w-[65%] max-w-[800px]">
          <p className="tracking-normal font-poppins leading-5 sm:leading-8 text-xs sm:text-base text-justify sm:text-center text-black">
            {aim?.description}
          </p>
          {aim?.buttonLink && aim?.buttonText && (
            <div className="mt-6">
              <a
                href={aim.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded"
              >
                {aim.buttonText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurPhilosophy;
