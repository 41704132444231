import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchData, ApiResponse } from "../services/api";

interface PackageSlider {
  packageId: string;
  title: string;
  subText: string;
  image: string;
  facilities: string;
  buttonText: string;
  buttonLink: string;
}

const Packages: React.FC = () => {
  const [packages, setPackages] = useState<PackageSlider[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPackagesData = async () => {
      try {
        const response: ApiResponse<{
          package_sliders: PackageSlider[];
        }> | null = await fetchData<{ package_sliders: PackageSlider[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        if (response?.data?.package_sliders) {
          setPackages(response.data.package_sliders);
        }
      } catch (error) {
        console.error("Error fetching packages data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPackagesData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const sliderSettings = {
    dots: false, // Hide the dots
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <section className="bg-gradient-to-b from-[#01316E] via-[#737373] to-[#01316E] text-white p-3 sm:p-10 md:p-  lg:h-[670px]">
      <div className="container mx-auto">
        <h1 className="text-xl lg:text-3xl font-akshar font-bold uppercase text-center mb-4">
          Discover Bespoke Journeys
        </h1>
        <Slider {...sliderSettings}>
          {packages.map((pkg) => (
            <div key={pkg.packageId} className="px-0 lg:px-4">
              <div className="relative rounded-lg overflow-hidden shadow-lg h-[500px]">
                <img
                  src={pkg.image}
                  onError={(e) => (e.currentTarget.src = "/fallback-image.jpg")}
                  alt={pkg.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-4 left-4 right-4 text-white">
                  <h3 className="text-base sm:text-sm font-medium tracking-wide">{pkg.title}</h3>
                  <p className="text-xs">{pkg.subText}</p>
                  {/* <p className="text-xs mt-1 text-[#E6D783]">
                    {pkg.facilities || "No facilities listed"}
                  </p> */}
                  {pkg.buttonText && pkg.buttonLink && (
                    <a
                      href={pkg.buttonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-block mt-2 uppercase  border border-white text-white py-1 px-2 text-sm font-semibold rounded"
                    >
                      {pkg.buttonText}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Packages;
