import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import BlogCard from "./BlogCard";
import { fetchData, ApiResponse } from "../services/api";

interface BlogData {
  blogId: string;
  title: string;
  description: string;
  image: string;
  createdAt: string;
  categories: string;
  authorName: string | null;
  authorImage: string;
}

const BlogSection: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize); // Add resize event listener

    return () => window.removeEventListener("resize", checkScreenSize); // Cleanup on unmount
  }, []);

  // Fetch blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response: ApiResponse<{ blogs: BlogData[] }> | null =
          await fetchData({
            url: "pages/get",
            data: { pageId: 1 },
          });

        const blogData = response?.data?.blogs || [];
        const filteredBlogs = blogData.filter((blog) =>
          blog.categories.includes("1")
        );
        setBlogs(filteredBlogs);
      } catch (err) {
        console.error("Error fetching blogs:", err);
        setError("Failed to load blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <section className="bg-white px-4 md:px-20">
      <div className="container mx-auto relative">
        <h2 className="text-xl lg:text-3xl font-bold font-akshar mb-8 uppercase text-center">
          Travel Blog
        </h2>

        {!isMobile && (
          <>
            <button
              className="absolute left-[-30px] top-1/2 transform -translate-y-1/2 z-10 w-10 h-10  rounded-full flex items-center justify-center shadow-md  transition swiper-button-prev"
              aria-label="Previous"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              className="absolute right-[-60px] top-1/2 transform -translate-y-1/2 z-10 w-10 h-10  rounded-full flex items-center justify-center shadow-md transition swiper-button-next"
              aria-label="Next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </>
        )}

        <Swiper
          modules={!isMobile ? [Navigation] : []}
          navigation={
            !isMobile
              ? { prevEl: ".swiper-button-prev", nextEl: ".swiper-button-next" }
              : false
          }
          spaceBetween={20}
          breakpoints={{
            640: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 20 },
          }}
          className="custom-swiper"
        >
          {blogs.map((post) => (
            <SwiperSlide key={post.blogId}>
              <div className="px-0">
                <BlogCard
                  category="Travel"
                  imageUrl={post.image}
                  date={new Date(post.createdAt).toDateString()}
                  comments={0}
                  title={post.title}
                  description={post.description.substring(0, 150) + "..."}
                  author={post.authorName || "Admin"}
                  authorImageUrl={post.authorImage}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default BlogSection;
