import React, { useState } from "react";

interface CardItemProps {
  imageUrl: string;
  title: string;
  onClick?: () => void;
}

const CardItem: React.FC<CardItemProps> = ({ imageUrl, title, onClick }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div
      className="relative h-[300px] " // Fixed height for mobile
      onMouseEnter={() => handleMouseEnter(0)}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-full object-cover rounded-lg"
      />
      <div className="absolute bottom-0 w-full px-2 py-1">
        <div
          className={`opacity-60 w-full h-full absolute top-0 left-0 rounded-lg bg-white transition-all duration-300 ease-in-out ${
            activeIndex !== null
              ? "h-full flex justify-center items-center"
              : "h-20"
          }`}
        ></div>
        <div
          className={`relative z-10 text-indigo-900 font-bold px-4 py-2 transition-all duration-300 ease-in-out flex justify-center items-center ${
            activeIndex !== null ? "text-center h-full" : ""
          }`}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default CardItem;
