import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface Partner {
  id: string;
  title: string;
  image: string;
}

const Clients: React.FC = () => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response: ApiResponse<{ partners: Partner[] }> | null =
          await fetchData<{
            partners: Partner[];
          }>({
            url: "pages/get",
            data: { pageId: 2 },
          });

        const partnersData = response?.data?.partners;

        if (partnersData && partnersData.length > 0) {
          setPartners(partnersData);
        } else {
          throw new Error("No partners data found.");
        }
      } catch (error) {
        setError("Failed to load partners data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  if (loading) {
    return <p className="text-center text-white">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-600">{error}</p>;
  }

  return (
    <div className="bg-gray-100 w-full py-8 px-4 md:px-12">
      <div className="clients-container flex flex-wrap justify-around items-center gap-6 md:gap-12">
        {partners.map((partner) => (
          <img
            key={partner.id}
            src={partner.image}
            alt={partner.title}
            className="client-logo w-20 h-auto md:w-32 object-contain"
          />
        ))}
      </div>
    </div>
  );
};

export default Clients;
