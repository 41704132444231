import React from "react";
import Card from "./MonthlyDestinationCard";
import Image1 from "../assets/images/ViewDestination1.png";
import Image2 from "../assets/images/ViewDestination2.png";
import Image3 from "../assets/images/ViewDestination3.png";

const cardsData = [
  {
    title: "Guatemala",
    description:
      "Guatemala does culture in the way that Costa Rica does nature. Lesser-known and largely untapped, there’s endless to keep you busy (and surprised). Mayan ruins at Uaxactun and Tikal and the glassy beauty of Lake Atitlan await.",
    imageUrl: Image1,
  },
  {
    title: "Guatemala",
    description:
      "Guatemala does culture in the way that Costa Rica does nature. Lesser-known and largely untapped, there’s endless to keep you busy (and surprised). Mayan ruins at Uaxactun and Tikal and the glassy beauty of Lake Atitlan await.",
    imageUrl: Image2,
  },
  {
    title: "Guatemala",
    description:
      "Guatemala does culture in the way that Costa Rica does nature. Lesser-known and largely untapped, there’s endless to keep you busy (and surprised). Mayan ruins at Uaxactun and Tikal and the glassy beauty of Lake Atitlan await.",
    imageUrl: Image3,
  },
];

const MonthlyDestinationList: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 md:px-28  min-h-screen">
      <h2 className="text-xl sm:text-3xl lg:text-3xl mb-6 font-semibold font-akshar  text-center uppercase">
        View more destinations
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 justify-items-center w-full mt-4 max-w-6xl">
        {cardsData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default MonthlyDestinationList;
