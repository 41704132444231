import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/images/logo.svg";
import { useParams } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { Link } from "react-router-dom";

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const HeaderCountry: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [bannerData, setBannerData] = useState<{
    bannerTitle: string;
    bannerImage: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.post(
          "https://demo.decordtech.com/tadmin/api/pages/CountryPageDetails",
          { countryId: id },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success && response.data.data.bannerData) {
          setBannerData(response.data.data.bannerData[0]); // Assuming bannerData is an array
        } else {
          throw new Error("No data available for this country.");
        }
      } catch (err) {
        console.error("Error fetching banner data:", err);
        setError("Failed to fetch banner data. Please try again later.");
      }
    };

    if (id) {
      fetchBannerData();
    }
  }, [id]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <nav
      className="w-full top-0 bg-transparent z-10 relative h-screen bg-cover bg-center"
      role="navigation"
      style={{
        backgroundImage: `url(${bannerData?.bannerImage})`,
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-10 text-white text-center px-4 sm:px-0">
        <h1 className="tracking-widest uppercase text-4xl sm:text-6xl font-bold">
          {bannerData?.bannerTitle || "Loading..."}
        </h1>
      </div>

      {/* Wrapper for logo and hamburger menu */}
      <div className="flex items-center justify-between w-full px-4 md:px-20 z-20 relative">
        {/* Logo */}
        <Link to="/" className="flex-shrink-0">
          <img src={logo} alt="Logo" className="h-16 w-16 md:h-24 md:w-24" />
        </Link>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex items-center tracking-widest space-x-6 lg:space-x-16 text-neutral-100">
          <li>
            <Link to="/" className="p-2 hover:text-white">
              HOME
            </Link>
          </li>
          <li>
            <Link to="/destination" className="p-2 hover:text-white">
              DESTINATION
            </Link>
          </li>
          <li>
            <Link to="/experiences" className="p-2 hover:text-white">
              EXPERIENCES
            </Link>
          </li>
          <li className="text-white font-bold text-sm lg:text-base">
            303-317-6945
          </li>
          <button>
            <Link
              to="/contact-us"
              className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
            >
              Ready To Travel?
            </Link>
          </button>
        </ul>

        {/* Hamburger Menu Button for Mobile */}
        <button
          onClick={toggleMenu}
          className="md:hidden focus:outline-none text-white"
          aria-label="Toggle menu"
          aria-expanded={isOpen}
        >
          {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <div className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-blue-900">
          <button
            className="absolute top-4 right-4 text-white"
            onClick={toggleMenu}
            aria-label="Close menu"
          >
            <FaTimes size={24} />
          </button>
          <ul className="space-y-4 text-lg font-bold text-white text-center">
            <li>
              <Link to="/" onClick={toggleMenu}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="/destination" onClick={toggleMenu}>
                DESTINATION
              </Link>
            </li>
            <li>
              <Link to="/experiences" onClick={toggleMenu}>
                EXPERIENCES
              </Link>
            </li>
            <button>
              <Link
                to="/contact-us"
                className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              >
                Ready To Travel?
              </Link>
            </button>
          </ul>
        </div>
      )}

      <div className="absolute left-8 bottom-8 flex flex-col items-center space-y-2 text-white sm:left-4 sm:bottom-4">
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3"
        >
          <FaFacebookF />
        </a>
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3"
        >
          <FaInstagram />
        </a>
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3"
        >
          <GrLinkedinOption />
        </a>
        <a
          href="#"
          className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3"
        >
          <FaTwitter />
        </a>
      </div>
    </nav>
  );
};

export default HeaderCountry;
