import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import FamilyAgency from "../components/FamilyAgency";
import BannerFamilyTravel from "../components/BannerfamilyTravel";
import FamilyContact from "../components/FamilyContact";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData } from "../services/api";

const FamilyTravel: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });
  const [error, setError] = useState<string | null>(null);

  // Fetch data for planning trip section
  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 5 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 2 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          // Update state
          setMetaData({
            pageTitle: "Destination Wedding", // Optional static page title
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });

          // Dynamically update meta tags
          const updateMetaTag = (name: string, content: string) => {
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            if (metaTag) {
              metaTag.setAttribute("content", content);
            } else {
              metaTag = document.createElement("meta");
              metaTag.setAttribute("name", name);
              metaTag.setAttribute("content", content);
              document.head.appendChild(metaTag);
            }
          };

          updateMetaTag("keywords", meta_keyword);
          updateMetaTag("description", meta_description);
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Navbar heading="DENVER'S TOP RATED FAMILY TRAVEL AGENCY" subHeading="" />
      <FamilyAgency />
      <BannerFamilyTravel />
      <FamilyContact />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}
      <Clients />
      <Footer />
    </div>
  );
};

export default FamilyTravel;
