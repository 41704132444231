import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import Footerlogo from "../assets/images/Footer-Logo.png";

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the mobile menu when a link is clicked
  };
  useEffect(() => {
    // Disable scrolling when the menu is open
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "auto"; // Ensure scrolling is restored
    };
  }, [isOpen]);

  return (
    <div>
      <div className="flex justify-between items-center py-4 bg-black px-4 md:justify-around">
        <div className="flex">
          <Link to="/" className="p-2">
            <img src={Footerlogo} alt="Logo" className="h-16 md:h-20" />
          </Link>
        </div>

        <button className="md:hidden text-white" onClick={toggleMenu}>
          <FaBars size={20} />
        </button>
        <div
          className={`hidden md:flex items-center space-x-12 text-neutral-100`}
        >
          <ul className="flex items-center space-x-4">
            <li className="hover:text-white cursor-pointer">
              <div className="border border-[#FFFFFF] rounded-full p-1 flex items-center justify-center">
                <CiSearch size={20} />
              </div>
            </li>
            <li className="hover:text-white cursor-pointer">
              <Link to="/">HOME</Link>
            </li>
            <div className="relative group hover:text-white cursor-pointer">
              <li>
                {" "}
                <Link to="/ourspecialities" className="p-2">
                  OUR SPECIALTIES
                </Link>
              </li>

              {isDropdownOpen && (
                <div className="absolute uppercase left-0 mt-2 w-48 bg-blue-900 text-white rounded shadow-lg z-20">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:text-yellow-400">
                      <Link to="/family-travel" onClick={handleLinkClick}>
                        Family Travel
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:text-yellow-400">
                      <Link to="/adventure-travel" onClick={handleLinkClick}>
                        Group Travel
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:text-yellow-400">
                      <Link to="/luxury-travel" onClick={handleLinkClick}>
                        Luxury Travel
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <Link to="/aboutus" className="p-2" onClick={handleLinkClick}>
              ABOUT US
            </Link>
            <li className="hover:text-white cursor-pointer uppercase">
              {" "}
              <Link to="/blog" className="p-2" onClick={handleLinkClick}>
                Blog
              </Link>
            </li>
            <Link to="/contact-us" className="p-2" onClick={handleLinkClick}>
              CONTACT US
            </Link>
          </ul>
        </div>
        <div className="hidden md:block">
          <button>
            <Link
              to="/contact-us"
              className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-500"
            >
              Ready To Travel?
            </Link>
          </button>
        </div>
      </div>

      {/* Full-Screen Mobile Menu */}
      <div
        className={`fixed  inset-0 bg-black bg-opacity-90 transition-opacity duration-300 ${
          isOpen ? "opacity-100 z-20" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className={`flex flex-col items-center justify-center h-full transition-transform duration-300 ${
            isOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          <button
            className="absolute top-4 right-4 text-white hover:text-yellow-400 transition duration-300"
            onClick={toggleMenu}
          >
            <FaTimes size={24} />
          </button>
          <ul className="text-white text-center space-y-6">
            <li className="hover:text-yellow-400" onClick={handleLinkClick}>
              <Link to="/">HOME</Link>
            </li>
            <li className="hover:text-yellow-400" onClick={handleLinkClick}>
              <Link to="/ourspecialities">OUR SPECIALTIES</Link>
            </li>
            <li className="hover:text-yellow-400" onClick={handleLinkClick}>
              <Link to="/aboutus">ABOUT US</Link>
            </li>
            <li className="hover:text-yellow-400" onClick={handleLinkClick}>
              <Link to="/blog">BLOG</Link>
            </li>
            <li className="hover:text-yellow-400" onClick={handleLinkClick}>
              <Link to="/contact-us">CONTACT US</Link>
            </li>
            <li>
              <button>
                <Link
                  to="/contact-us"
                  onClick={toggleMenu}
                  className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
                >
                  Ready To Travel?
                </Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
